<template>
  <section
    id="dashboard-ecommerce"
  >
    <b-row
      class="match-height"
    >
      <b-col md="4">
        <NewMember :data="newMembers" />
      </b-col>
      <b-col md="8">
        <Lotto
          :data="huaySummaryItem"
          :last-update="lastUpdate"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        lg="4"
      >
        <b-row class="match-height">
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <CommissionChart :data="newBalanceInfo.winLose" />
          </b-col>
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-profit-chart :data="newBalanceInfo.profit" />
          </b-col>
          <b-col
            lg="12"
            md="6"
          >
            <ecommerce-earnings-chart :data="earnings" />
          </b-col>
        </b-row>
      </b-col>
      <b-col
        lg="8"
      >
        <BalanceInfo :data="balanceInfo" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        sm="12"
        md="6"
        lg="8"
      >
        <ActiveUser :data="activeUsers" />
      </b-col>
      <b-col
        md="6"
        lg="4"
      >
        <DownLineSummarize
          ref="downline"
          :data="downLine"
          :agent="agent"
          :member="member"
        />
      </b-col>
      <b-col
        sm="12"
      >
        <NewDownLine :data="newDownLine" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
/* eslint-disable */
import {
  BCol,
  BRow,
} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import DownLineSummarize from '@/views/dashboard/statistics/DownLineSummarize.vue'
import NewDownLine from '@/views/dashboard/statistics/NewDownLine.vue'
import ActiveUser from '@/views/dashboard/statistics/ActiveUser.vue'
import BalanceInfo from '@/views/dashboard/statistics/BalanceInfo.vue'
import NewMember from '@/views/dashboard/statistics/NewMember.vue'
import CommissionChart from '@/views/dashboard/statistics/CommissionChart.vue'
import Lotto from '@/views/dashboard/statistics/Lotto.vue'
import EcommerceProfitChart from '@/views/dashboard/ecommerce/EcommerceProfitChart.vue'
import EcommerceEarningsChart from '@/views/dashboard/ecommerce/EcommerceEarningsChart.vue'
import reportService from '@/service/reportService'

// eslint-disable-next-line no-extend-native
Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000)
  return this
}
const d = new Date()

// eslint-disable-next-line no-unused-vars
function getRandomInt(min, max) {
  // eslint-disable-next-line no-param-reassign
  min = Math.ceil(min)
  // eslint-disable-next-line no-param-reassign
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export default {
  components: {
    NewMember,
    CommissionChart,
    EcommerceEarningsChart,
    StatisticCardHorizontal,
    StatisticCardVertical,
    EcommerceProfitChart,
    BRow,
    BCol,
    DownLineSummarize,
    NewDownLine,
    ActiveUser,
    BalanceInfo,
    Lotto,
  },
  data() {
    return {
      status: null,
      sum: {
        bet: 0,
        pay: 0,
        income: 0,
        poy: 0,
        poyPerUser: 0,
      },
      newsletter: {},
      data: {},
      agent : 0,
      member : 0,
      downLine: {
        agent: 0,
        member: 0,
        series: [{
          value: 0,
          name: 'Agent'
        }, {
          value: 0,
          name: 'Member'
        }],
      },
      activeUsers: {
        series: [{
          name: 'Agent',
          data: [],
        }]
      },
      newDownLine: {
        series: [{
          name: 'Agent',
          data: [],
        }, {
          name: 'Member',
          data: [],
        }],
      },

      balanceInfo: {
        series: [{
          data: [],
        }, {
          data: [],
        }, {
          data: [],
        }],
      },

      earnings: {
        progress: 0,
        earning: 0,
        upsize: 0,
        series: [0]
      },

      newMembers: {
        series: [
          {
            data: []
          }
        ]
      },
      huaySummaryItem: [],

      newBalanceInfo: {
        winLose: {
          series: [{
            data: [],
          }]
        },
        profit: {
          series: [{
            data: [],
          }]
        },
      },

      lastUpdate: '',

    }
  },

  async  beforeMount() {
    const res = await  reportService.getAuthen('');
    const data = res.data
    if (res.status === 200) {
      this.newMembers.series[0].data = data.newMembers

      const huaySummary = data.huaySummary

      this.lastUpdate = `Updated ${this.timeSince(new Date(data.lastUpdate * 1000))} ago`

      this.huaySummaryItem = [huaySummary.bet, huaySummary.pay, huaySummary.profit, huaySummary.poy]


      this.downLine.agent = data.downlineSummary.agent
      this.downLine.series[0].value = this.downLine.agent
      this.downLine.member = data.downlineSummary.member
      this.downLine.series[1].value = this.downLine.member


      this.agent = this.downLine.agent;
      this.member = this.downLine.member;

      this.activeUsers.series[0].data = data.activeUsers

      this.earnings = {
        progress: data.earnings.progress,
        earning: data.earnings.earning,
        upsize: data.earnings.upsize,
        series: [data.earnings.progress]
      }
      // this.earnings.earning = 4000;
      // this.earnings.upsize = 50

      for (let i = 0; i < data.balanceInfo.length; i++) {
        const b = data.balanceInfo[i]
        this.balanceInfo.series[0].data.push(b.commission)
        this.balanceInfo.series[1].data.push(b.turnOver)
        this.balanceInfo.series[2].data.push(b.winLose)
      }

      for (let i = 0; i < data.downlines.length; i++) {
        const d = data.downlines[i]
        this.newDownLine.series[0].data.push(d.agent)
        this.newDownLine.series[1].data.push(d.member)
      }

      for (let i = 0; i < data.newBalanceInfo.length; i++) {
        const b = data.newBalanceInfo[i]
        this.newBalanceInfo.winLose.series[0].data.push(b.winLose)
        this.newBalanceInfo.profit.series[0].data.push(b.profit)
      }
    }
  },
  methods: {
    ParseDate(_d) {
      return `${_d.getFullYear()}/${this.pad2(_d.getMonth() + 1)}`
    },
    ParseDate2(_d) {
      return (
          `${this.pad2(_d.getDate())
          }/${
              this.pad2(_d.getMonth() + 1)
          }/${
              _d.getFullYear()}`
      )
    },
    pad2(number) {
      return (number < 10 ? '0' : '') + number
    },
    timeSince(date) {

      var seconds = Math.floor((new Date() - date) / 1000)

      var interval = seconds / 31536000

      if (interval > 1) {
        return Math.floor(interval) + ' years'
      }
      interval = seconds / 2592000
      if (interval > 1) {
        return Math.floor(interval) + ' months'
      }
      interval = seconds / 86400
      if (interval > 1) {
        return Math.floor(interval) + ' days'
      }
      interval = seconds / 3600
      if (interval > 1) {
        return Math.floor(interval) + ' hours'
      }
      interval = seconds / 60
      if (interval > 1) {
        return Math.floor(interval) + ' minutes'
      }
      return Math.floor(seconds) + ' seconds'
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
