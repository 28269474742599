<template>
  <b-card
    v-if="data"
    class="card-tiny-line-stats"
    body-class="pb-50"
  >
    <h6>New Members Today</h6>
    <h2 class="font-weight-bolder mb-1">
      <!--      6,24k-->
      {{ kFormatter(today) }}
    </h2>
    <!-- chart -->
    <!--    <vue-apex-charts-->
    <!--      height="70"-->
    <!--      :options="statisticsProfit.chartOptions"-->
    <!--      :series="data.series"-->
    <!--    />-->
    <e-charts
      ref="chart"
      style="width:100%;height:100px"
      autoresize
      :options="option"
      theme="theme-color"
      auto-resize
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { $themeColors } from '@themeConfig'
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/bar'
import theme from '@core/components/charts/echart/theme.json'

import { kFormatter } from '@core/utils/filter'

ECharts.registerTheme('theme-color', theme)

// eslint-disable-next-line no-unused-vars
const $trackBgColor = '#EBEBEB'

export default {
  components: {
    BCard,
    ECharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      today: 0,
      option: {
        // height: '100',
        grid: {
          left: '0px',
          right: '0px',
          top: '0px',
          bottom: '15px',
        },
        xAxis: {
          axisLine: {
            show: false, // Hide full Line
          },
          xisTick: {
            show: false, // Hide Ticks,
          },
          splitLine: { show: false },
          type: 'category',
        },
        tooltip: {
          show: false,
        },
        yAxis: {
          axisLine: {
            show: false, // Hide full Line
          },
          xisTick: {
            show: false, // Hide Ticks,
          },
          splitLine: { show: false },
          type: 'value',
        },
        series: [{
          data: this.data.series[0].data,
          type: 'line',
          smooth: true,
          // symbolSize: '5',
          showSymbol: false,
          lineStyle: {
            width: 3,
            // shadowColor: 'rgba(0,0,0,0.3)',
            // shadowBlur: 10,
            // shadowOffsetY: 8,
          },
        }],
      },
    }
  },
  watch: {
    data: {
      handler(after) {
        const len = after.series[0].data.length
        if (len > 0) {
          this.today = after.series[0].data[len - 1]
        }
        this.option.series[0].data = after.series[0].data
        this.$refs.chart.mergeOptions(this.option)
      },
      deep: true,
    },
  },
  mounted() {
    // const series = this.option.series[0]
    // series.symbolSize = '20'
    // this.$refs.chart.mergeOptions(this.option)
    const len = this.data.series[0].data.length
    if (len > 0) {
      this.today = this.data.series[0].data[len - 1]
    }
  },
  methods: {
    kFormatter,
  },
}
</script>
