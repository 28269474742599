<template>
  <b-card
    v-if="data"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Statistics</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        {{ lastUpdate }}
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in options"
          :key="item.icon"
          xl="3"
          sm="6"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ kFormatter(item.title) }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
/* eslint-disable prefer-destructuring */
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    lastUpdate: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      options: [
        {
          color: 'light-primary',
          customClass: 'mb-2 mb-xl-0',
          icon: 'TrendingUpIcon',
          subtitle: 'Bet',
          // title: '230k',
          title: parseInt(this.data[0] || 0, 10),
        },
        {
          color: 'light-danger',
          customClass: 'mb-2 mb-sm-0',
          icon: 'MinusIcon',
          subtitle: 'Pay',
          // title: '1.423k',
          title: parseInt(this.data[1] || 0, 10),
        },
        {
          color: 'light-success',
          customClass: 'mb-2 mb-xl-0',
          icon: 'DollarSignIcon',
          subtitle: 'Profit',
          // title: '$9745',
          title: parseInt(this.data[2] || 0, 10),
        },
        {
          color: 'light-warning',
          customClass: 'mb-sm-0',
          icon: 'FileIcon',
          subtitle: 'Poy',
          // title: '8.549k',
          title: parseInt(this.data[3] || 0, 10),
        },
      ],
    }
  },
  watch: {
    // lastUpdate(after) {
    //   // console.log(after)
    // },
    data: {
      handler(after) {
        this.options[0].title = parseInt(after[0], 10)
        this.options[1].title = parseInt(after[1], 10)
        this.options[2].title = parseInt(after[2], 10)
        this.options[3].title = parseInt(after[3], 10)
      },
      deep: true,
    },
  },
  created() {
  },
  methods: {
    kFormatter,
  },
}
</script>
