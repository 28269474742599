<template>
  <b-card
    v-if="data"
    no-body
  >
    <b-card-header>
      <h4 class="mb-0">
        Active User
      </h4>
    </b-card-header>

    <!--    <app-echart-bar-->
    <!--      style="width:100%;height:100%;min-height: 300px"-->
    <!--      :option-data="option"-->
    <!--    />-->
    <e-charts
      ref="chart"
      style="width:100%;height:100%;min-height: 300px"
      autoresize
      :options="option"
      theme="theme-color"
      auto-resize
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader,
} from 'bootstrap-vue'

import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/line'
import theme from '@core/components/charts/echart/theme.json'
import { kFormatter } from '@core/utils/filter'

ECharts.registerTheme('theme-color', theme)

// eslint-disable-next-line no-unused-vars
const currentDate = new Date()

// eslint-disable-next-line no-unused-vars
function GetDaysInMonth(month, year) {
  return new Date(year, month, 0).getDate()
}

export default {
  components: {
    BCard,
    BCardHeader,
    ECharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      option: {
        xAxis: [
          {
            splitLine: {
              lineStyle: {},
            },
            data: Array.from({ length: GetDaysInMonth(currentDate.getMonth() + 1, currentDate.getFullYear()) }, (_, i) => `${i + 1}`),
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              lineStyle: {},
            },
            axisLabel: {
              formatter: v => `${kFormatter(v)}`,
            },
          },
        ],
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          left: '60px',
          right: '20px',
          bottom: '40px',
          top: '10x',
        },
        series: [{
          data: this.data.series[0].data,
          type: 'line',
        }],
      },
    }
  },
  computed: {
    skin() {
      return this.$store.state.appConfig.layout.skin
    },
  },
  watch: {
    skin(newSkin) {
      this.updateTheme(newSkin)
    },
    data: {
      handler(after) {
        this.option.series[0].data = after.series[0].data
        this.$refs.chart.mergeOptions(this.option)
      },
      deep: true,
    },
  },
  mounted() {
    this.updateTheme(this.skin)
    this.$refs.chart.mergeOptions(this.option)
  },
  methods: {
    updateTheme(skin) {
      if (skin === 'dark') {
        this.option.xAxis[0].splitLine.lineStyle.color = ['#3b4253']
        this.option.yAxis[0].splitLine.lineStyle.color = ['#3b4253']
      } else {
        this.option.xAxis[0].splitLine.lineStyle.color = theme.valueAxis.splitLine.lineStyle.color
        this.option.yAxis[0].splitLine.lineStyle.color = theme.valueAxis.splitLine.lineStyle.color
      }
      this.$refs.chart.mergeOptions(this.option)
    },
  },
}
</script>

<style scoped>

</style>
