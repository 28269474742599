<template>
  <b-card
    v-if="data"
    no-body
  >
    <b-card-header>
      <h4 class="mb-0">
        New Downline
      </h4>
    </b-card-header>

    <e-charts
      ref="chart"
      style="width:100%;height:100%;min-height: 300px"
      autoresize
      :options="option"
      theme="theme-color"
      auto-resize
    />
  </b-card>
</template>

<script>
/* eslint-disable */
import {
  BCard, BCardHeader,
} from 'bootstrap-vue'
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/bar'
import theme from '@core/components/charts/echart/theme.json'
import { kFormatter } from '@core/utils/filter'

ECharts.registerTheme('theme-color', theme)
// eslint-disable-next-line no-unused-vars
const currentDate = new Date()

// eslint-disable-next-line no-unused-vars
function GetDaysInMonth(month, year) {
  return new Date(year, month, 0).getDate()
}

GetDaysInMonth(currentDate.getMonth(), currentDate.getFullYear())

export default {
  components: {
    BCard,
    BCardHeader,
    ECharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      option: {
        xAxis: [
          {
            splitLine: { show: false },
            type: 'category',
            data: Array.from({ length: GetDaysInMonth(currentDate.getMonth()+1, currentDate.getFullYear()) }, (_, i) => `${i + 1}`),
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              lineStyle: {},
            },
            axisLabel: {
              formatter: v => `${kFormatter(v)}`,
            },
          },
        ],
        legend: {
          right: '20px',
          top: '5px',
        },
        grid: {
          left: '45px',
          right: '20px',
          bottom: '40px',
          top: '45x',
        },
        series: [{
          name : this.data.series[0].name,
          data: this.data.series[0].data,
          type: 'bar',
          label: {
            show: false,
          },
          stack: 'downline',
        },
        {
          name : this.data.series[1].name,
          data: this.data.series[1].data,
          type: 'bar',
          label: {
            show: true,
            position: 'top',
            color: 'black',
            fontSize: '10',
            textBorderColor: 'white',
            textBorderWidth: '5',
            // fontWeight: 'bold',
          },
          stack: 'downline',
        }],
      },
    }
  },
  computed: {
    skin() {
      return this.$store.state.appConfig.layout.skin
    },
  },
  watch: {
    skin(newSkin) {
      this.updateTheme(newSkin)
    },
  },
  mounted() {
    const series = this.option.series
    this.option.series[1].label.formatter = (v)=> series[0].data[v.dataIndex] + series[1].data[v.dataIndex]

    this.updateTheme(this.skin)
  },
  methods: {
    updateTheme(skin) {
      if (skin === 'dark') {
        this.option.yAxis[0].splitLine.lineStyle.color = ['#3b4253']
      } else {
        this.option.yAxis[0].splitLine.lineStyle.color = theme.valueAxis.splitLine.lineStyle.color
      }
      this.$refs.chart.mergeOptions(this.option)
      // this.$refs.chart.refresh()
    },
  },
}
</script>

<style scoped>

</style>
