<template>
  <b-card
    v-if="data"
    body-class="pb-50"
  >
    <!--    <h6>Commission</h6>-->
    <h6>WinLose</h6>
    <h2 class="font-weight-bolder mb-1">
      <!--      2,76k-->
      {{ kFormatter(today) }}
    </h2>
    <!-- chart -->
    <vue-apex-charts
      ref="chart"
      height="70"
      :options="statisticsOrder.chartOptions"
      :series="data.series"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

import { kFormatter } from '@core/utils/filter'

const $barColor = '#f3f3f3'

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      today: 0,
      statisticsOrder: {
        chartOptions: {
          id: 'chart01',
          chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
              top: -15,
              bottom: -15,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '20%',
              startingShape: 'rounded',
              colors: {
                backgroundBarColors: [$barColor, $barColor, $barColor, $barColor, $barColor],
                backgroundBarRadius: 5,
              },
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.warning],

          xaxis: {
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
    }
  },
  watch: {
    data: {
      handler(after) {
        // this.data.series = after.series
        const len = after.series[0].data.length
        if (len > 0) {
          this.today = after.series[0].data[len - 1]
        }
        this.$refs.chart.updateSeries(after.series)
      },
      deep: true,
    },
  },
  mounted() {
    const len = this.data.series[0].data.length
    if (len > 0) {
      this.today = this.data.series[0].data[len - 1]
    }
  },
  methods: {
    kFormatter,
  },
}
</script>
