<template>
  <b-card
    v-if="data"
    no-body
  >
    <b-card-header>
      <h4 class="mb-0">
        Downline Summarize
      </h4>
    </b-card-header>

    <!--    <vue-apex-charts-->
    <!--      height="245"-->
    <!--      :options="goalOverviewRadialBar"-->
    <!--      :series="data.series"-->
    <!--    />-->
    <e-charts
      ref="chart"
      style="width:100%;height:100%;min-height: 300px;"
      autoresize
      :options="option"
      theme="theme-color"
      auto-resize
    />
    <b-row
      v-if="data.series.length > 0"
      class="text-center mx-0"
    >
      <b-col
        cols="6"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="mb-0">
          Agent
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ kFormatter(agent) }}
        </h3>
      </b-col>

      <b-col
        cols="6"
        class="border-top d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="mb-0">
          Member
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ kFormatter(member) }}
        </h3>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BRow, BCol, BCardText,
} from 'bootstrap-vue'
// import { $themeColors } from '@themeConfig'

import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/pie'
import theme from '@core/components/charts/echart/theme.json'
import { kFormatter } from '@core/utils/filter'

ECharts.registerTheme('theme-color', theme)
// const $strokeColor = '#ebe9f1'
// const $textHeadingColor = '#5e5873'
// const $goalStrokeColor2 = '#51e5a8'
// const $agentColor = '#A9A2F6'

export default {
  components: {
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
    ECharts,
  },
  props: {
    agent: {
      type: Number,
      default: () => 0,
    },
    member: {
      type: Number,
      default: () => 0,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: 'item',
        },
        legend: {
          orient: 'vertical',
          right: '20px',
        },
        series: [
          {
            type: 'pie',
            radius: '70%',
            data: this.data.series,
            itemStyle: {
              normal: {
                opacity: 0.7,
                borderWidth: 3,
                // borderColor: $themeColors.secondary,
              },
            },
            label: {
              show: false,
              // fontSize: 14,
            },
            labelLine: {
              lineStyle: {
                // color: $themeColors.secondary,
              },
            },
            // selectedMode: 'single',
            // selectedOffset: 30,
            // clockwise: true,
          },
        ],
      },
    }
  },
  computed: {
    skin() {
      return this.$store.state.appConfig.layout.skin
    },
  },
  watch: {
    skin(newSkin) {
      this.updateTheme(newSkin)
    },
    agent(after) {
      this.agent = after
      this.$forceUpdate()
    },
    member(after) {
      this.member = after
      this.$forceUpdate()
    },
    // data: {
    //   handler(after) {
    //     // this.data = after
    //     // this.data.agent = after.agent
    //     // this.data.member = after.member
    //     this.agent = after.agent
    //     this.member = after.member
    //     this.$forceUpdate()
    //   },
    //   deep: true,
    // },
  },
  mounted() {
    this.updateTheme(this.skin)
  },
  methods: {
    TextColor(color) {
      return {
        dark: '#D0D4DB',
        light: '#5e5873',
      }[color]
    },
    updateTheme(skin) {
      if (skin === 'dark') {
        // this.option.series[0].itemStyle.normal.borderColor = this.TextColor(this.skin)
        this.option.series[0].label.color = this.TextColor(this.skin)
        this.option.series[0].labelLine.lineStyle.color = this.TextColor(this.skin)
      } else {
        // this.option.series[0].itemStyle.normal.borderColor = this.TextColor(this.skin)
        this.option.series[0].label.color = this.TextColor(this.skin)
        this.option.series[0].labelLine.lineStyle.color = this.TextColor(this.skin)
      }
      this.$refs.chart.mergeOptions(this.option)
    },
    kFormatter,
  },
}
</script>
